<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img src="@/assets/images/logo/logo.svg" alt="logo" height="60" />
        <!-- <h2 class="brand-text text-primary ml-1">
          Bubblin
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="sideImg" alt="Login" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Bienvenue sur <span class="bubblin_text">Bubble</span><span class="bubblin_text">In</span>!
          </b-card-title>
          <b-card-text class="mb-2">
            Veuillez vous connecter à votre compte
          </b-card-text>

          <!-- <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | admin</small>
              </p>
              <p>
                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert> -->

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group
                label="Identifiant"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="identifiant"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="Identifiant communiqué par mail"
                  />
                <small class="text-danger" v-html="errors[0]"/>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
               <div class="d-flex justify-content-between">
                 <label for="login-password">Mot de passe</label>
                 <b-link :to="{name:'forget-password'}">
                   <small>Mot de passe oublié ?</small>
                 </b-link>
               </div>
                <validation-provider
                  #default="{ errors }"
                  name="mot de passe"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Mot de passe"
                    />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
<!--              <b-form-group>-->
<!--                <b-form-checkbox-->
<!--                  id="remember-me"-->
<!--                  v-model="status"-->
<!--                  name="checkbox-1"-->
<!--                >-->
<!--                  Se souvenir de moi-->
<!--                </b-form-checkbox>-->
<!--              </b-form-group>-->

              <!-- submit buttons :disabled="invalid"-->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="requested"
              >
                <span style="margin-right:5px;">Me connecter</span>   <b-spinner v-show="requested" small type="grow"></b-spinner>
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
          <p class="mt-1 mb-0 font-italic font-weight-bold">Pour contacter le support :</p>
          <p class="font-italic small mb-0">- Téléphone : <a href="tel:0175952299" class="font-weight-bolder">01 75 95 22 99</a></p>
          <p class="font-italic small mb-0">- Email : <a href="mailto:support@bubble-in.com" class="font-weight-bolder">support@bubble-in.com</a></p>
          <p class="font-italic small ">Vous pouvez également utiliser le bouton Assistance/Chat en bas à gauche</p>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, BSpinner, VBTooltip } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { is401, is429 } from '@/shared/utils/response'
import axios from 'axios'
import url from '@/shared/api/parametre'
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BSpinner,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: (this.$route.params.username) ? this.$route.params.username : '',
      sideImg: require('@/assets/images/login/login_slide.png'),

      // validation rules
      required,
      email,
      requested: false,
      url: '',

    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    // imgUrl() {
    //   if (store.state.appConfig.layout.skin === 'dark') {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     this.sideImg = require('@/assets/images/login/login_slide.png')
    //     return this.sideImg
    //   }
    //   return this.sideImg
    // }
  },
  created(){
    this.url = localStorage.getItem('url') ? localStorage.getItem('url'): ''
    localStorage.removeItem('accessToken')
    localStorage.removeItem('userData')
    localStorage.clear()
    localStorage.setItem('url', this.url);
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.requested = true
          new Promise((resolve, reject) => {
            axios
              .post(`${url}auth/login`, { name: this.userEmail, password: this.password })
              .then(response => {
                // console.log(response);
                if(response.data.data == "reset_password"){
                  this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Changement de mot de passe`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: response.data.message
                      }
                    })
                }
                else{
                  localStorage.setItem('showPopupProvisional', true);
                  const userData = response.data.user
                  const personne = userData.personne_physique
                  userData.role = 'admin'
                  userData.ability = [{ action: 'manage', subject: 'all' }]
                  userData.avatar = require('@/assets/images/avatars/13-small.png')
                  userData.formationdda = localStorage.getItem('url')
                  useJwt.setToken(response.data.token)
                  useJwt.setRefreshToken(null)
                  localStorage.setItem('userData', JSON.stringify(userData))
                  localStorage.setItem('userPages', JSON.stringify([]))
                  // this.loadPusher(response.data.token)
                  this.$ability.update(userData.ability)
                  // if (this.$route.params.user_new) {
                  //   this.$router.replace('/collaborateur').then(() => {
                  //     this.$toast({
                  //       component: ToastificationContent,
                  //       position: 'top-right',
                  //       props: {
                  //         title: `Bienvenue ${personne.nom +' '+ personne.prenom}`,
                  //         icon: 'CoffeeIcon',
                  //         variant: 'success',
                  //         // text: 'Vous vous êtes connecté avec succès en tant que courtier. Vous pouvez maintenant commencer à explorer!'
                  //       }
                  //     })
                  //   })
                  // } else {
                  localStorage.setItem('showPopup', true);

                    this.$router.replace(getHomeRouteForLoggedInUser(userData)).then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Bienvenue ${personne.nom +' '+ personne.prenom}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          // text: "Vous vous êtes connecté avec succès en tant qu'administrateur. Vous pouvez maintenant commencer à explorer!"
                        }
                      })
                      this.$store.commit('messageries/setRefresh', true)
                      this.$store.dispatch('messageries/fetchEmails',true)
                    })
                  // }
                } 
                this.requested = false
              })
              .catch(error => {
                if (error.response.status == 401 || error.response.status == 429) {
                  if(error.response.status == 429){
                    error = {
                      email: 'Suite à un nombre important de tentative, votre compte a été bloqué. Merci de contacter le support BubbleIn pour le débloquer.',
                      password: ' '
                    }
                  }
                  else
                  {
                    error = {
                      email: error.response.data.errors[0],
                      password: ' '
                    }
                  }
                  this.$refs.loginForm.setErrors(error)
                  this.requested = false
                } 
              })
          })
        }
      })
    },
    loadPusher(token) {
      //Pusher
      window.Pusher = Pusher
      // This assumes you have already saved your bearer token in your browsers local storage
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'bubbleinkey',
        cluster: 'mt1',
        wsHost: 'test.bubble-in.com', 
        authEndpoint: 'https://test.bubble-in.com/api/broadcasting/auth',
        wsPort: 6001,
        wssPort: 6001,
        disableStats: true,
        forceTLS: true,
        encrypted: true,
        enabledTransports: ['ws', 'wss'],
        auth: {
          headers: {
              Authorization: 'Bearer ' + token,
              Accept: 'application/json',
              'X-Tenant': 'my-tenant',
          },
        },
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style>
@import url('https://fonts.cdnfonts.com/css/chicken-pie-height');
.bubblin_text {
  font-family: 'CHICKEN Pie Height', sans-serif;
  color: #4d25bc;
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
}
.bubblin_text::first-letter {
  font-size: 25px;
}
.spinner-grow{
  vertical-align: initial;
}
</style>
